<template>
  <div style="margin-left: 50px; overflow-y: auto; height: 74vh">
    <h2>Настройки времени хранения данных</h2>
    <div>
      <el-row class="data-storage-row">
        <el-col :span="6">
          Лог-файлы на диске
        </el-col>
        <el-col :span="6">
          Период хранения
          <el-input-number
            v-model="systemSettings.logFilesStoragePeriod"
            size="mini"
            style="width: 100px"
            controls-position="right"
            :min="1"
            :max="365"
          ></el-input-number>
          дней
        </el-col>
        <el-col :span="5">
          Запускать в
          <el-time-select
            v-model="systemSettings.logFilesClearTime"
            size="small"
            :clearable="false"
            style="width: 100px"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:59'
            }">
          </el-time-select>
          по МСК
        </el-col>
  <!--      <el-col :span="4">
          <el-button type="primary">Очистить сейчас</el-button>
        </el-col>-->
      </el-row>
      <el-row class="data-storage-row">
        <el-col :span="6">
          Лог в базе данных
        </el-col>
        <el-col :span="6">
          Период хранения
          <el-input-number
            v-model="systemSettings.logDatabaseStoragePeriod"
            size="mini"
            style="width: 100px"
            controls-position="right"
            :min="1"
            :max="365"
          ></el-input-number>
          дней
        </el-col>
        <el-col :span="5">
          Запускать в
          <el-time-select
            v-model="systemSettings.logDatabaseClearTime"
            size="small"
            :clearable="false"
            style="width: 100px"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:59'
            }">
          </el-time-select>
          по МСК
        </el-col>
  <!--      <el-col :span="4">
          <el-button type="primary">Очистить сейчас</el-button>
        </el-col>-->
      </el-row>
      <el-row class="data-storage-row">
        <el-col :span="6">
          Файловое хранилище
        </el-col>
        <el-col :span="6">
          Период хранения
          <el-input-number
            v-model="systemSettings.filesStoragePeriod"
            size="mini"
            style="width: 100px"
            controls-position="right"
            :min="1"
            :max="365"
          ></el-input-number>
          дней
        </el-col>
        <el-col :span="5">
          Запускать в
          <el-time-select
            v-model="systemSettings.filesClearTime"
            size="small"
            :clearable="false"
            style="width: 100px"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:59'
            }">
          </el-time-select>
          по МСК
        </el-col>
  <!--      <el-col :span="4">
          <el-button type="primary">Очистить сейчас</el-button>
        </el-col>-->
      </el-row>
      <el-row class="data-storage-row">
        <el-col :span="6">
          Сырые данные внешних пользователей
        </el-col>
        <el-col :span="6">
          Период хранения
          <el-input-number
            v-model="systemSettings.externalUsersDataStoragePeriod"
            size="mini"
            style="width: 100px"
            controls-position="right"
            :min="1"
            :max="365"
          ></el-input-number>
          дней
        </el-col>
        <el-col :span="5">
          Запускать в
          <el-time-select
            v-model="systemSettings.externalUsersDataClearTime"
            size="small"
            :clearable="false"
            style="width: 100px"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:59'
            }">
          </el-time-select>
          по МСК
        </el-col>
  <!--      <el-col :span="4">
          <el-button type="primary">Очистить сейчас</el-button>
        </el-col>-->
      </el-row>
      <el-row class="data-storage-row">
        <el-col :span="6">
          Удаленные записи в базе данных
        </el-col>
        <el-col :span="6">
          Период хранения
          <el-input-number
            v-model="systemSettings.deletedRowsStoragePeriod"
            size="mini"
            style="width: 100px"
            controls-position="right"
            :min="1"
            :max="365"
          ></el-input-number>
          дней
        </el-col>
        <el-col :span="5">
          Запускать в
          <el-time-select
            v-model="systemSettings.deletedRowsClearTime"
            size="small"
            :clearable="false"
            style="width: 100px"
            :picker-options="{
              start: '00:00',
              step: '00:15',
              end: '23:59'
            }">
          </el-time-select>
          по МСК
        </el-col>
        <!--      <el-col :span="4">
                <el-button type="primary">Очистить сейчас</el-button>
              </el-col>-->
      </el-row>
    </div>
    <hr/>
    <h2>Настройки сопоставления</h2>
    <el-row class="data-storage-row">
      <el-col :span="6">
        Настройка временного лага
      </el-col>
      <el-col :span="6">
        <el-input-number
          v-model="systemSettings.timeLag"
          size="mini"
          style="width: 100px"
          controls-position="right"
          :min="1"
          :max="365"
        ></el-input-number>
        минут
      </el-col>
    </el-row>
    <hr/>
    <div class="mt-2" style="text-align: center">
      <el-button @click="handleSaveSystemSettings" size="small" type="primary" :loading="loading">
        Сохранить
      </el-button>
    </div>
  </div>
</template>

<script>

import sApi from '@/api/administration/systemSettings';

export default {
  name: 'SystemSettings',
  data() {
    return {
      loading: false,
      systemSettings: {
        /* logFilesStoragePeriod: 7,
        logFilesClearTime: '00:30',
        logDatabaseStoragePeriod: 8,
        logDatabaseClearTime: '00:45',
        filesStoragePeriod: 9,
        filesClearTime: '01:30',
        externalUsersDataStoragePeriod: 10,
        externalUsersDataClearTime: '01:45',
        timeLag: 2
         */
      },
    };
  },
  async mounted() {
    await this.getSystemSettings();
  },
  methods: {
    async getSystemSettings() {
      this.loading = true;
      const res = await sApi.getSystemSettings();
      if (res.data) {
        res.data.logFilesClearTime = res.data.logFilesClearTime.substring(0, res.data.logFilesClearTime.length - 3);
        res.data.logDatabaseClearTime = res.data.logDatabaseClearTime.substring(0, res.data.logDatabaseClearTime.length - 3);
        res.data.filesClearTime = res.data.filesClearTime.substring(0, res.data.filesClearTime.length - 3);
        res.data.externalUsersDataClearTime = res.data.externalUsersDataClearTime.substring(0, res.data.externalUsersDataClearTime.length - 3);
        res.data.deletedRowsClearTime = res.data.deletedRowsClearTime.substring(0, res.data.deletedRowsClearTime.length - 3);
        this.systemSettings = res.data;
      }
      this.loading = false;
    },
    async handleSaveSystemSettings() {
      this.loading = true;
      const res = await sApi.updateSystemSettings(this.systemSettings);
      if (res.data) {
        this.$message({
          type: 'success',
          message: 'Сохранено успешно'
        });
      }

      this.loading = false;
    }
  }
};
</script>

<style scoped>
  h2 {
    font-size: 16px;
  }
  hr {
    border-color: lightgrey;
    background-color: lightgray;
    color: lightgray;
    display: block;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 1em 0;
    padding: 0;
  }
  .el-col {
    font-size: 12px;
  }
  /* .data-storage-row {
    min-height: 50px;
    line-height: 50px;

  } */
</style>
